import React from 'react'

const formStatus = props => {
  const visibilityClass = props.status.initial ? '' : ' active'
  const colorClass = props.status.success ? 'is-success' : 'is-danger'
  const handleCloseNotification = (e) => {
    e.target.parentNode.parentNode.removeChild(e.target.parentNode)
  }
  return (
    <div className={`field form__notification${visibilityClass}`}>
      <div className={`notification ${colorClass}`}>
        <button className='delete' onClick={e => handleCloseNotification(e)} />
        {props.status.error || props.status.success}
      </div>
    </div>
  )
}

export default formStatus
