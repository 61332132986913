import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage } from 'formik'
import Recaptcha from 'react-recaptcha'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import { parseISO } from 'date-fns'
import formatDate from 'date-fns/format'
import { enUS } from 'date-fns/locale'
import { MultiSelect } from 'react-sm-select'

import reservationFormWrapper from '../../../hoc/reservationFormWrapper'
import FormStatus from '../../FormStatus/FormStatus'
import locationsData from '../../../data/locations.json'
import packagesData from '../../../data/packages.json'

class ReservationForm extends Component {
  constructor (props) {
    super(props)
    this.recaptchaInstance = ''
  }

  componentDidMount () {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    const datePickerInput = document.querySelector('.DayPickerInput input')
    datePickerInput.classList.add('input')
    if (this.props.location) {
      const preselectedLocation = this.props.location.replace('-', ' ')
      this.props.values.location = preselectedLocation
      const formElement = document.querySelector('.is-two-fifths')
      let formTopOffset = formElement.getBoundingClientRect().top
      if (window.innerWidth <= 768) formTopOffset -= 64
      setTimeout(() => window.scrollTo({ top: formTopOffset, behavior: 'smooth' }), 400)
    }
  }

  componentDidUpdate () {
    if (!this.props.status.initial) this.recaptchaInstance.reset()
  }

  parseInputDate = (str) => {
    const parsed = parseISO(str)
    if (DateUtils.isDate(parsed)) return parsed
    return undefined
  }

  formatInputDate = (date, format) => {
    return formatDate(date, format, { locale: enUS })
  }

  handleNumberOfGuestsChange = val => {
    let inputValue = val
    if (isNaN(inputValue)) return
    if (/^0/.test(inputValue)) inputValue = inputValue.replace(/^0/, 5)
    if (!this.props.touched.guests) this.props.setFieldTouched('guests')
    if (inputValue !== '') inputValue = Number(inputValue)
    this.props.setFieldValue('guests', inputValue)
  }

  handleIncreaseNumberOfGuests = () => {
    const guestsAmount = this.props.values.guests
    if (guestsAmount === 300 || isNaN(guestsAmount)) return
    if (guestsAmount === '') {
      this.props.setFieldValue('guests', 5)
      if (!this.props.touched.guests) this.props.setFieldTouched('guests')
    } else {
      this.props.setFieldValue('guests', (guestsAmount + 1))
    }
  }

  handleDecreaseNumberOfGuests = () => {
    const guestsAmount = this.props.values.guests
    if (guestsAmount === 5 || guestsAmount === '' || isNaN(guestsAmount)) return
    this.props.setFieldValue('guests', (guestsAmount - 1))
  }

  formatPhoneValue = (val) => {
    if (val.length < 10) return val
    const eliminationRegExp = /[\s()-]/gi
    const cleansedVal = val.replace(eliminationRegExp, '')
    const formattedVal = ['(', cleansedVal.slice(0, 3), ')', ' ', cleansedVal.slice(3, 6), '-', cleansedVal.slice(6)].join('')
    return formattedVal
  }

  render () {
    const locations = locationsData.map(location => location.name)
    const packages = packagesData.map(packageItem => packageItem.title.replace(/\n\n/g, '').replace(/\n/g, ' '))

    const locationOptions = locations.map(location => { return { value: location === 'Richardson Buffet' ? 'Richardson' : location, label: location } })
    const packageOptions = packages.map(packageItem => { return { value: packageItem, label: packageItem } })

    const {
      values,
      handleSubmit,
      handleBlur,
      handleChange,
      isValid,
      isSubmitting,
      status,
      errors,
      touched,
      setFieldValue,
      setFieldTouched
    } = this.props

    const recaptchaSiteKey = '6LetSLMUAAAAAF9-JEvkpo-XLi02zjn9B9eNQbaO'

    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className='field'>
            <div className='control'>
              <input
                type='text'
                placeholder='Name'
                name='name'
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.name && touched.name ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='name' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='email'
                placeholder='E-mail address'
                name='email'
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.email && touched.email ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='email' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='tel'
                placeholder='Phone Number'
                name='phone'
                value={values.phone}
                onBlur={handleBlur}
                onChange={e => {
                  const newValue = this.formatPhoneValue(e.target.value)
                  setFieldValue('phone', newValue)
                }}
                className={`input${errors.phone && touched.phone ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='phone' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className={`control${errors.location && touched.location ? ' is-danger' : ''}`}>
              <MultiSelect
                mode='single'
                value={[values.location]}
                onChange={value => {
                  setFieldValue('location', value[0])
                }}
                onBlur={() => setFieldTouched('location', true)}
                options={locationOptions}
                id='location'
                valuePlaceholder='Select Location'
              />
            </div>
            <ErrorMessage name='location' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field has-addons'>
            <div className='control'>
              <button type='button' className='button' onClick={this.handleDecreaseNumberOfGuests}>
                <span className='icon'>
                  <FontAwesomeIcon icon='minus' />
                </span>
              </button>
            </div>
            <div className='control is-expanded'>
              <input
                type='number'
                placeholder='Number of Guests'
                name='guests'
                value={values.guests}
                onBlur={handleBlur}
                onChange={ev => this.handleNumberOfGuestsChange(ev.target.value)}
                className={`input${errors.guests && touched.guests ? ' is-danger' : ''}`}
              />
            </div>
            <div className='control'>
              <button type='button' className='button' onClick={this.handleIncreaseNumberOfGuests}>
                <span className='icon'>
                  <FontAwesomeIcon icon='plus' />
                </span>
              </button>
            </div>
          </div>
          <ErrorMessage name='guests' render={msg => <p className='help is-danger translated-slightly-upwards has-tiny-bottom-margin'>{msg}</p>} />

          <div className='field'>
            <div className={`control${errors.date && touched.date ? ' date-danger' : ''}`}>
              <DayPickerInput
                placeholder='Date of Event'
                onDayChange={day => setFieldValue('date', day)}
                dayPickerProps={{
                  fromMonth: new Date(),
                  disabledDays: {
                    before: new Date()
                  },
                  selectedDays: values.date
                }}
                inputProps={{
                  name: 'date',
                  onBlur: handleBlur,
                  type: 'text',
                  readOnly: true
                }}
                value={values.date}
                formatDate={this.formatInputDate}
                format='MM/dd/yy'
                parseDate={this.parseInputDate}
                keepFocus={false}
              />
            </div>
            <ErrorMessage name='date' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className={`control${errors.preferred && touched.preferred ? ' is-danger' : ''}`}>
              <MultiSelect
                mode='single'
                value={[values.preferred]}
                onChange={value => {
                  setFieldValue('preferred', value[0])
                }}
                onBlur={() => setFieldTouched('preferred', true)}
                options={packageOptions}
                id='preferred'
                valuePlaceholder='Preferred Package'
              />
            </div>
            <ErrorMessage name='preferred' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <textarea
                placeholder='Message'
                name='message'
                value={values.message}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`textarea${errors.message && touched.message ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='message' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <Recaptcha
                sitekey={recaptchaSiteKey}
                render='explicit'
                ref={e => { this.recaptchaInstance = e }}
                verifyCallback={response => {
                  setFieldValue('recaptcha', response)
                }}
                onloadCallback={() => setFieldValue('recaptcha', '')}
              />
            </div>
            <ErrorMessage name='recaptcha' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <button
                type='submit'
                className={`button is-primary${isSubmitting ? ' is-loading' : ''}`}
                disabled={!isValid || isSubmitting}
              >
                <span className='icon'>
                  <FontAwesomeIcon icon='paper-plane' />
                </span>
                <span>Submit Reservation</span>
              </button>
            </div>
          </div>

          <FormStatus status={status} />

        </form>
      </>
    )
  }
}

export default reservationFormWrapper(ReservationForm)
