import React from 'react'

import ThirdCardWithImage from '../UiElements/ThirdCardWithImage'
import RegularCardContainer from '../UiElements/RegularCardContainer'

const locationList = props => {
  const locations = props.locations.map((locationCard, index) => {
    return (
      <ThirdCardWithImage
        imageSource={locationCard.image}
        imageAlt={locationCard.name}
        title={locationCard.name}
        description={locationCard.description}
        link={`/events-banquets/gallery/${locationCard.name.replace(' ', '-')}`}
        linkText={`Take a tour of our ${locationCard.name} event spaces.`}
        key={index}
      />
    )
  })
  return (
    <RegularCardContainer addClasses={['default-responsive-cards']}>
      {locations}
    </RegularCardContainer>
  )
}

export default locationList
