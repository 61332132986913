import React from 'react'

// Internal modules
import Footer from '../components/Footer/Footer'

const mainLayout = props => {
  let outerClasses = 'hero'
  if (props.fullheight) outerClasses = 'hero is-fullheight-with-navbar'
  let innerClasses = 'hero-body'
  if (props.paddingless) innerClasses = 'hero-body is-paddingless'
  if (props.alignstart) innerClasses += ' align-to-start'
  const { noFooter, ...otherProps } = props
  return (
    <div className={outerClasses}>
      {props.passedabovebody || ''}
      <div className={innerClasses} {...otherProps}>
        {props.children}
      </div>
      {
        noFooter ? undefined : <Footer />
      }
    </div>
  )
}

export default mainLayout
