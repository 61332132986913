import React from 'react'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

const thirdCardWithImage = props => {
  let imageSection = (
    <figure className='image is-square third-card-with-image__image'>
      <img src={props.imageSource} alt={props.imageAlt} />
    </figure>
  )
  if (props.link) {
    const url = new URL(props.link, window.location.href)

    if (url.host !== window.location.host) {
      imageSection = (
        <a href={props.link}>
          <figure className='image is-square third-card-with-image__image'>
            <img src={props.imageSource} alt={props.imageAlt} />
          </figure>
        </a>
      )
    } else {
      imageSection = (
        <Link to={props.link}>
          <figure className='image is-square third-card-with-image__image'>
            <img src={props.imageSource} alt={props.imageAlt} />
          </figure>
        </Link>
      )
    }
  }
  return (
    <div className='column is-4'>
      {imageSection}
      <div className='box is-radiusless is-shadowless'>
        <p className='is-size-5 has-text-weight-semibold'>{props.title}</p>
        <ReactMarkdown source={props.description} />
        {props.children}
        {props.link && <Link to={props.link}>{props.linkText}</Link>}
      </div>
    </div>
  )
}

export default thirdCardWithImage
