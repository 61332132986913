import React from 'react'

const regularContainerForFourths = props => {
  const cardsArray = React.Children.toArray(props.children)
  return (
    <div className='columns'>
      <div className='column is-half'>
        <div className='columns default-responsive-cards'>
          {cardsArray[0]}
          {cardsArray[1]}
        </div>
      </div>
      <div className='column is-half'>
        <div className='columns default-responsive-cards'>
          {cardsArray[2]}
          {cardsArray[3]}
        </div>
      </div>
    </div>
  )
}

export default regularContainerForFourths
