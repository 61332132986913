import { withFormik } from 'formik'
import axios from 'axios'
import unwrap from 'async-unwrap'

import careerSchema from '../validation/CareerSchema'
import { baseURL } from '../config'

const careerFormWrapper = withFormik({
  validationSchema: careerSchema,
  handleSubmit: async (formData, { setStatus, resetForm, setSubmitting }) => {
    const sendToApi = { ...formData }
    const [err, res] = await axios.post(new URL('careers', baseURL).href, sendToApi)[unwrap] // eslint-disable-line no-unused-vars

    const statusToSet = { initial: false }

    if (err) {
      statusToSet.error = 'Something went wrong. Please try again later.'
      setStatus(statusToSet)
      setSubmitting(false)
      setTimeout(() => {
        setStatus({ ...statusToSet, initial: true })
      }, 5000)
      return
    }

    resetForm()
    statusToSet.success = 'Your message was sent successfully'
    setStatus(statusToSet)
    setSubmitting(false)
    setTimeout(() => {
      setStatus({ ...statusToSet, initial: true })
    }, 5000)
  },
  mapPropsToValues: () => ({
    name: '',
    email: '',
    phone: '',
    positions: [],
    locations: [],
    xp: '',
    previous: '',
    days: [],
    hours: 20,
    date: '',
    recaptcha: ''
  }),
  mapPropsToErrors: () => ({
    readyForSubmission: 'Not ready'
  }),
  mapPropsToStatus: () => ({
    initial: true
  }),
  displayName: 'CareerForm'
})

export default careerFormWrapper
