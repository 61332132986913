import React from 'react'
import { Helmet } from 'react-helmet'

// internal modules
import DesktopNavBar from '../components/DesktopNavBar/DesktopNavBar'
import MainLayout from '../containers/MainLayout'

const about = props => (
  <>
    <Helmet>
      <title>Our Story - Andalous Mediterranean Grill</title>
    </Helmet>
    <DesktopNavBar />
    <MainLayout fullheight='true' alignstart='true'>
      <div className='container'>
        <div className='columns'>
          <div className='column is-half'>
            <figure className='image about-page__image'>
              <img src='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/about-owners.jpg' alt='About' />
            </figure>
          </div>
          <div className='column is-half'>
            <h1 className='title is-size-2 is-size-3-mobile'>About</h1>
            <p className='has-medium-bottom-margin'>
              Andalous Mediterranean Grill is a neighborhood favorite in the Dallas area.  Our original location in Las Colinas, which opened in 2012, continues to be a popular destination.  Our stores in Arlington and Richardson have also greeted crowds with our delicious Mediterranean dishes.
            </p>
            <p className='has-medium-bottom-margin'>
              Twin chefs, Sam and Wassim, spent years operating their family's award winning restaurants and now bring together their expertise through the Andalous Mediterranean Grill chain of restaurants. After years of training and cooking from their mother's secret recipes, the brothers have learned the traditions of countries bordering the Mediterranean Sea.
            </p>
            <p className='has-medium-bottom-margin'>
              The twins have brought a new twist to the old traditions with decadent dips, warm handmade bread, charcoal cooked kebabs, and tempting desserts. The diverse menu offers a variety of choices for any low calorie, low carb, or vegetarian diet.
            </p>
            <p className='has-medium-bottom-margin'>
              We hope to see you soon.
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  </>
)

export default about
