import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

// internal modules
import DesktopNavBar from '../components/DesktopNavBar/DesktopNavBar'
import MainLayout from '../containers/MainLayout'
import FoodCategories from '../components/FoodCategories/FoodCategories'
import FetchData from '../helpers/fetchData'

class Menu extends Component {
  render () {
    return (
      <>
        <Helmet>
          <title>Meat Entrées, Platters, Salads, Sandwiches - Andalous Mediterranean Grill</title>
        </Helmet>
        <DesktopNavBar />
        <MainLayout paddingless='true' fullheight='true'>
          <div className='container'>
            <FetchData for={FoodCategories} dataProp='foodCategories' requestedData='featuredFoodCategories' />
          </div>
        </MainLayout>
      </>
    )
  }
}

export default Menu
