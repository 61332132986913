import React from 'react'

const regularCardContainer = props => {
  const classes = ['columns'].concat(props.addClasses || []).join(' ')
  return (
    <div className={classes}>
      {props.children}
    </div>
  )
}

export default regularCardContainer
