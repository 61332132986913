import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class desktopNavBar extends Component {
  state = {
    needsSpace: true
  }

  componentDidMount () {
    // Desktop height transition for navbar
    window.addEventListener('scroll', this.determineIfSpaceNeeded)
    this.determineIfSpaceNeeded()

    // Activating mobile menu
    if (window.innerWidth < 1024) {
      // Get all "navbar-burger" elements
      const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)

      // Check if there are any navbar burgers
      if ($navbarBurgers.length > 0) {
        // Add a click event on each of them
        $navbarBurgers.forEach(el => {
          el.addEventListener('click', () => {
            // Get the target from the "data-target" attribute
            const target = el.dataset.target
            const $target = document.getElementById(target)

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active')
            $target.classList.toggle('is-active')
          })
        })
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.determineIfSpaceNeeded)
  }

  determineIfSpaceNeeded = () => {
    const needsSpace = window.scrollY < 1
    if (this.state.needsSpace !== needsSpace) this.setState({ needsSpace })
  }

  render () {
    return (
      <nav className={`navbar is-fixed-top has-shadow andalous-taste${this.state.needsSpace ? ' is-spaced' : ''}`} role='navigation' aria-label='main navigation'>
        <div className='navbar-brand'>
          <a className='navbar-item' href='/'>
            <img src='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/andalous-logo-tagged-regged-194w.png' alt='Andalous logo' />
          </a>

          <button className='navbar-burger burger' aria-label='menu' aria-expanded='false' data-target='navMenu'>
            <span aria-hidden='true' />
            <span aria-hidden='true' />
            <span aria-hidden='true' />
          </button>
        </div>

        <div className='navbar-menu' id='navMenu'>
          <div className='navbar-center'>
            <Link to='/' className='navbar-item'>
              Home
            </Link>
            <Link to='/menu' className='navbar-item'>
              Menu
            </Link>
            <a href='https://order.andalousgrill.com' className='navbar-item'>
              Order Online
            </a>
            <a href='https://order.andalousgrill.com/catering' className='navbar-item' rel='noopener noreferrer' target='_blank'>
              Catering
            </a>
            <Link to='/events-banquets' className='navbar-item'>
              Events / Banquets
            </Link>
            <Link to='/locations' className='navbar-item'>
              Locations
            </Link>
            <Link to='/about' className='navbar-item'>
              About
            </Link>
            <a href='/careers' className='navbar-item'>
              Careers
            </a>
            <a href='/feedback' className='navbar-item'>
              Feedback
            </a>
          </div>
        </div>
      </nav>
    )
  }
}

export default desktopNavBar
