import React from 'react'

import FourthCardWithoutImage from '../UiElements/FourthCardWithoutImage'

const packageCard = props => {
  let packageTitle = props.package.title
  let packageContents = <p>{props.package['short-description']}</p>

  if (props.package.type === 'regular') {
    packageTitle = `${props.package.title}\n${props.package.portion}\n$${props.package.price}`
    packageContents = (
      <ul>
        {props.package.items.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
    )
  }

  return (
    <>
      <FourthCardWithoutImage title={packageTitle}>
        <div className='content'>
          <p className='has-line-breaks'>{props.package.description}</p>
          {packageContents}
        </div>
      </FourthCardWithoutImage>
    </>
  )
}

export default packageCard
