// External modules
import React from 'react'

// Internal modules
import RegularUnderlinedTitleWithDesc from '../../UiElements/RegularUnderlinedTitleWithDesc'
import RegularCardContainer from '../../UiElements/RegularCardContainer'
import ThirdCardWithImage from '../../UiElements/ThirdCardWithImage'

const foodCategory = props => {
  const cards = props.foodCategory.items.map((foodCategoryCard, index) => {
    return (
      <ThirdCardWithImage
        imageSource={foodCategoryCard.image}
        imageAlt={foodCategoryCard.title}
        link={`https://order.andalousgrill.com/food/${foodCategoryCard.id}`}
        title={foodCategoryCard.title}
        description={foodCategoryCard.description}
        key={index}
      >
        <div className='level has-light-top-margin'>
          <div className='level-left'>
            <a href={`https://order.andalousgrill.com/food/${foodCategoryCard.id}`}>
              Order Now
            </a>
          </div>
          <div className='level-right has-text-weight-bold'>
            ${foodCategoryCard.price}
          </div>
        </div>
      </ThirdCardWithImage>
    )
  })
  return (
    <section className='section'>
      <RegularUnderlinedTitleWithDesc title={props.foodCategory.foodCategory} description={props.foodCategory.description} />
      <RegularCardContainer addClasses={['default-responsive-cards', 'is-multiline']}>
        {cards}
      </RegularCardContainer>
    </section>
  )
}

export default foodCategory
