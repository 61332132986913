import * as yup from 'yup'

import locationsData from '../data/locations.json'

const locations = locationsData.map(location => location.name === 'Richardson Buffet' ? 'Richardson' : location.name)
locations.push('Not location specific')
locations.push('Online Ordering')

const schema = yup.object().shape({
  first: yup.string('Letters only').min(2, 'Min. 2 chars').max(50, 'Max. 50 chars'),
  last: yup.string('Letters only').min(2, 'Min. 2 chars').max(50, 'Max. 50 chars'),
  email: yup.string('Email Address').required('Required').email('Enter a valid email address'),
  location: yup.mixed().required('Required').oneOf(locations, 'Please choose one of our locations'),
  message: yup.string('Letters only').required('Required').min(10, 'Min. 10 chars').max(1000, 'Max. 1000 chars'),
  recaptcha: yup.string().required('Required')
})

export default schema
