import React from 'react'

import GalleryLayout from '../containers/GalleryLayout'
import EventsBanquetsGallery from '../components/EventsBanquetsGallery/EventsBanquetsGallery'

import restaurants from '../data/restaurants.json'

const eventsBanquetsGallery = props => (
  <GalleryLayout returnUrl='/events-banquets'>
    <EventsBanquetsGallery galleryItems={restaurants} />
  </GalleryLayout>
)

export default eventsBanquetsGallery
