import React from 'react'
import { Helmet } from 'react-helmet'

// internal modules
import DesktopNavBar from '../components/DesktopNavBar/DesktopNavBar'
import MainLayout from '../containers/MainLayout'
import Locations from '../components/Locations/Locations'

// example data
import locations from '../data/locations.json'

const locationsPage = props => {
  return (
    <>
      <Helmet>
        <title>Locations Dallas, Irving, Richardson, Arlington - Andalous Mediterranean Grill</title>
      </Helmet>
      <DesktopNavBar />
      <MainLayout fullheight='true'>
        <div className='container'>
          <Locations locationData={locations} />
        </div>
      </MainLayout>
    </>
  )
}

export default locationsPage
