import React from 'react'

import MainLayout from '../containers/MainLayout'
import DesktopNavBar from '../components/DesktopNavBar/DesktopNavBar'

const error404 = props => {
  return (
    <>
      <DesktopNavBar />
      <MainLayout fullheight='true'>
        <div className='container has-text-centered is-error-404-container'>
          <h1 className='title is-404-title has-text-grey'>404</h1>
          <p className='has-text-grey is-404-content'>We couldn't find the page you were looking for.<br />Please use the navigation menu to select another.</p>
        </div>
      </MainLayout>
    </>
  )
}

export default error404
