import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

const footer = props => (
  <div className={classNames('website-footer', props.classes, {
    'hero-foot': !props.notHero
  })}
  >
    <div className='container has-medium-vertical-padding'>
      <p className={classNames('has-text-centered is-size-7', {
        'has-text-grey': !props.notHero
      })}
      >
        <a className='is-size-4 mr-4 has-text-dark' href='https://www.facebook.com/AndalousGrill/' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={['fab', 'facebook']} /></a>
        <a className='is-size-4 mr-4 has-text-dark' href='https://www.instagram.com/andalousgrill/' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
        <a className='is-size-4 has-text-dark' href='https://twitter.com/AndalousGrill/' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
        <br />
        © 2012 - {new Date().getFullYear()} Andalous Mediterranean Grill, LLC, All&nbsp;Rights&nbsp;Reserved
        <br />
        The Andalous Logo is a registered trademark of Andalous Mediterranean Grill, LLC
        <br />
        Copying or duplication of images contained herein is strictly forbidden without the prior written consent of Andalous Mediterranean Grill, LLC
        <br />
        <Link to='/privacy-policy'>Privacy Policy</Link> | <Link to='/terms-of-service'>Terms of Service</Link>
      </p>
    </div>
  </div>
)

export default footer
