import React from 'react'

import DesktopNavBar from '../components/DesktopNavBar/DesktopNavBar'
import MainLayout from '../containers/MainLayout'

const privacyPolicy = props => (
  <>
    <DesktopNavBar />
    <MainLayout fullheight='true'>
      <div className='container content'>
        <div className='columns'>
          <div className='column is-full'>
            <h1 className='title is-size-2 is-size-3-mobile'>Andalous Grill Privacy Policy</h1>

            <p>This Privacy Policy is intended to inform you how Andalous Mediterranean Grill Restaurants ("Andalous Grill") gathers, uses, discloses and manages your personal information in connection to your use of our website.  Andalous Grill provides this website as a digital service to our customers.  This website uses a mobile-responsive design which allows it to optimize its functionality for desktop computers, tablets and mobile phones that can access the internet through popular browsers, therefore, this privacy policy applies to this website regardless of the device by which it is accessed.</p>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Consent</h2>
              <p>Your use of this website constitutes your acceptance and agreement to our Terms of Use and Privacy Policy.</p>
            </section>

            <section className='section content'>
              <h2 className='subtitle is-size-4-mobile'>Information Collected by This Website</h2>
              <p>Andalous Grill collects information you provide directly via this website. This information can be used to identify you or can be reasonably linked, directly or indirectly to you. At no point in the data collection process does this website collect Personably Identifieable Information without your consent. You indicate consent by submitting your information or by agreeing to continue with a transaction.</p>
              <p>The categories of information we collect may include the following:</p>
              <ul>
                <li><p><strong>Contact Information:</strong> We collect your first and last name,  postal address, email address, and telephone number.</p>
                </li>
                <li><p><strong>Payment Data:</strong> We collect data necessary to process your payment if you make a purchase through our online ordering or catering features. Payment information such as your name, credit/debit card  information are transmitted to our credit card processor and used only while your transaction is pending. Your credit/debit card information is not stored by this website.</p>
                </li>
                <li><p><strong>Order Information:</strong> We collect data about your food choices and information that allows the fullfilment of your order if you submit an order through this website.</p>
                </li>
                <li><p><strong>Applicant Data:</strong> We collect data as necessary to consider you for a job opening if you submit an application through this website.</p>
                </li>
                <li><p><strong>Feedback Data</strong>: We collect the contents of feedback, suggestions, questions or inquries if you submit that data to us through the feedback form.</p>
                </li>
                <li><p><strong>Event / Banquet Data</strong>: We collect data regarding special events or banquets such as location, date, number of guests and postal addresses if you submit an inquiry or reservation through this website.</p>
                </li>
                <li><p><strong>Other Transactions:</strong>  We may offer other features, functionality or opportunities through this website that may require your to submit further information or data. All data is directly requested and may be provided at your disccretion.  This website does not collect Personably Identifieable Information without your knowledge and consent.</p>
                </li>
                <li><p><strong>Additional Information:</strong> You may choose to voluntarily disclose other information when using this website that we do not request, and, in such instances, you are solely responsible for such information. </p>
                </li>
              </ul>
            </section>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Cookies</h2>
              <ul>
                <li><p><strong>Metrics:</strong> This website uses Google Analytics and therefore uses cookies to collect anonymous data about website use.  We use this data to improve website functionality and deliver an acceptable user experience.</p>
                </li>
                <li><p><strong>Online Ordering &amp; Catering:</strong> We use cookies to retain the state of the website and in the online ordering and catering sections of our website.  Cookies are solely used to support website functional</p>
                </li>
              </ul>
            </section>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Disclosure of Your Information</h2>
              <p>Andalous Grill fully owns this website and retains it's control.  Andalous Grill has worked with a trusted third-party in the development of this website.</p>
              <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety.  However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
            </section>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Usage of Information</h2>
              <p>Any of the information we collect from you will be used to process your order, consider your skills for employment, faciliate the planning of an event and receive feedback.  This website only collects the information necessary for the transaction being performed and to fulfill the service being requested by the user.</p>
            </section>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Safeguards and Security</h2>
              <p>Andalous Grill maintains reasonable internal controls and  procedures to ensure that the information you share with us is handled in a safe, secure and responsible manner. Our security measures help protect against unauthorized access and alteration of the information in our control. We will only disclose such information to employees and authorized service providers who require  such information for the purposes of providing a product or service.</p>
              <p>We use industry standard e-commerce security technology such as Transport Layer Security (TLS) encryption technology. We use TLS technology to  prevent sensitive information from being intercepted and read as it is transmitted over the Internet. This website does not store credit card information.</p>
            </section>

          </div>
        </div>
      </div>
    </MainLayout>
  </>
)

export default privacyPolicy
