import React from 'react'
import { Helmet } from 'react-helmet'

import DesktopNavBar from '../components/DesktopNavBar/DesktopNavBar'
import MainLayout from '../containers/MainLayout'
import CareerForm from '../components/Forms/CareerForm/CareerForm'

const career = props => (
  <>
    <Helmet>
      <title>Employment and Careers, Apply Online - Andalous Mediterranean Grill</title>
    </Helmet>
    <DesktopNavBar />
    <MainLayout fullheight='true' alignstart='true'>
      <div className='container'>
        <div className='columns'>
          <div className='column is-half'>
            <h1 className='title is-size-2 is-size-3-mobile'>Careers</h1>
            <p className='has-medium-bottom-margin'>
              We appreciate your interest in applying for a position at Andalous Mediterranean Grill Restaurants. To begin the application process, please fill out the form below. We carefully review all applications and will contact qualified candidates for a formal interview.
            </p>
          </div>
          <div className='column is-half'>
            <CareerForm />
          </div>
        </div>
      </div>
    </MainLayout>
  </>
)

export default career
