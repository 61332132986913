import React from 'react'

import RegularContainerForFourths from '../UiElements/RegularContainerForFourths'
import PackageCard from './PackageCard'

const packageList = props => {
  const packages = props.packages.map((pack, index) => {
    return <PackageCard package={pack} key={index} />
  })
  return (
    <RegularContainerForFourths>
      {packages}
    </RegularContainerForFourths>
  )
}

export default packageList
