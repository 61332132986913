import React from 'react'

import FoodCategory from './FoodCategory/FoodCategory'

const foodCategories = props => {
  const content = props.foodCategories.filter(foodCat => foodCat.items.length !== 0).map((foodCategory, index) => {
    return <FoodCategory foodCategory={foodCategory} key={index} />
  })
  return (
    <>
      {content}
    </>
  )
}

export default foodCategories
