import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'

import EventsBanquetsGalleryItem from './EventsBanquetsGalleryItem'

class EventsBanquetsGallery extends Component {
  constructor (props) {
    super(props)
    const itemsQuantity = props.galleryItems.length
    this.state = {
      currentId: 0,
      itemsQuatity: itemsQuantity - 1
    }
  }

  componentDidMount () {
    const mainHtml = document.getElementsByTagName('html')[0]
    mainHtml.classList.remove('has-navbar-fixed-top')

    window.addEventListener('orientationchange', () => {
      this.setState({ currentId: 0 })
    })

    if (this.props.match.params.location) {
      switch (this.props.match.params.location) {
        case 'Arlington':
          if (window.innerWidth <= 768) window.scrollTo({ top: 0, behavior: 'smooth' })
          break
        case 'Las-Colinas':
          this.setState({ currentId: 7 })
          if (window.innerWidth <= 768) {
            const target = document.getElementById('Las-Colinas-start')
            window.scrollTo({ top: target.offsetTop, behavior: 'smooth' })
          }
          break
        case 'Richardson-Buffet':
          this.setState({ currentId: 14 })
          if (window.innerWidth <= 768) {
            const target = document.getElementById('Richardson-start')
            window.scrollTo({ top: target.offsetTop, behavior: 'smooth' })
          }
          break
        default:
          break
      }
    }
  }

  componentWillUnmount () {
    const mainHtml = document.getElementsByTagName('html')[0]
    mainHtml.classList.add('has-navbar-fixed-top')
  }

  handleGalleryLeftControl = e => {
    e.stopPropagation()
    const nextId = this.state.currentId === 0 ? this.state.itemsQuatity : (this.state.currentId - 1)
    this.setState({ currentId: nextId })
  }

  handleGalleryRightControl = e => {
    e.stopPropagation()
    const nextId = this.state.currentId === this.state.itemsQuatity ? 0 : (this.state.currentId + 1)
    this.setState({ currentId: nextId })
  }

  render () {
    const slidingTransformation = { transform: `translateX(${this.state.currentId * -100}%)` }

    const galleryItems = this.props.galleryItems.map((galleryItem, index) => {
      let addId = null
      if (index === 7) addId = 'Las-Colinas-start'
      if (index === 14) addId = 'Richardson-start'
      return <EventsBanquetsGalleryItem galleryItem={galleryItem} key={index} id={addId} />
    })

    return (
      <div className='gallery-container'>
        <div className='gallery-control left-control is-size-1 has-text-white' onClick={this.handleGalleryLeftControl}>
          <FontAwesomeIcon icon='angle-left' />
        </div>
        <div className='gallery-control right-control is-size-1 has-text-white' onClick={this.handleGalleryRightControl}>
          <FontAwesomeIcon icon='angle-right' />
        </div>
        <div className='gallery-tracker' style={slidingTransformation}>
          {galleryItems}
        </div>
      </div>
    )
  }
}

export default withRouter(EventsBanquetsGallery)
