import React from 'react'
import ReactMarkdown from 'react-markdown'

const eventsBanquetsGalleryItem = props => {
  const imageForBg = `url(${props.galleryItem.image})`

  return (
    <div className='gallery-item' id={props.id}>
      <div className='card is-shadowless'>
        <div className='card-image gallery-image-bearer' style={{ backgroundImage: imageForBg }} />
        <div className='card-content'>
          <div className='level gallery-level'>
            <div className='level-left'>
              <p className='title is-3'>{props.galleryItem.name}</p>
            </div>
            <div className='level-right'>
              <a href={props.galleryItem.link} className='button is-radiusless is-medium'>
                  Reserve This Space
              </a>
            </div>
          </div>
          <div className='gallery-description'>
            <ReactMarkdown source={props.galleryItem.description} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default eventsBanquetsGalleryItem
