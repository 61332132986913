import React from 'react'

const buttonLinkPairEqualWidth = props => (
  <div className='buttons'>
    <a href={props.link1} className='button is-radiusless is-fullwidth' target='_blank' rel='noopener noreferrer'>
      {props.buttonText1}
    </a>
    <a href={props.link2} className='button is-radiusless is-fullwidth' target='_blank' rel='noopener noreferrer'>
      {props.buttonText2}
    </a>
  </div>
)

export default buttonLinkPairEqualWidth
