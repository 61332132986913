import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// internal modules
import DesktopNavBar from '../components/DesktopNavBar/DesktopNavBar'
import MainLayout from '../containers/MainLayout'
import RegularUnderlinedTitleWithDesc from '../components/UiElements/RegularUnderlinedTitleWithDesc'
import LocationsList from '../components/LocationsList/LocationsList'
import PackagesList from '../components/PackagesList/PackagesList'
import ReservationForm from '../components/Forms/ReservationForm/ReservationForm'

// sample data
import locations from '../data/locations.json'
import packages from '../data/packages.json'

class EventsBanquets extends Component {
  render () {
    return (
      <>
        <Helmet>
          <title>Private Events, Banquets and Group Reservations - Andalous Mediterranean Grill</title>
        </Helmet>
        <DesktopNavBar />
        <MainLayout fullheight='true'>
          <div className='container'>
            <RegularUnderlinedTitleWithDesc
              title={['Host Your Event With', <span key='title'>&nbsp;</span>, 'Us']}
              description={['Select from our three locations in the Dallas / Ft. Worth metroplex. Our event spaces range from small to large and can ', <span key='text-insert' className='has-text-weight-bold'>accomodate groups of up to 120 guests</span>, '.  You may select from our packages described below or we can help you select a perfect menu for your guests.   Take a tour of our facilities and submit a reservation for the date and time of your event.  We will contact you to coordinate the menu, confirm your order and reserve the space on our calendar.']}
            />
            <LocationsList locations={locations} />
            <PackagesList packages={packages} />
            <div className='columns'>
              <div className='column is-three-fifths'>
                <RegularUnderlinedTitleWithDesc
                  title='Request a Reservation'
                  description='Please submit a reservation with the details for your event. We can provide you with a quote. You will be under no obligation.  We will contact you to discuss event details, confirm your menu requirements and ensure the space is reserved for your event. Thank you for considering Andalous Mediterranean Grill to serve your event needs.'
                />
                <h3 className='is-size-4'>Group Reservation & Private Event Agreement</h3>
                <p>Our agreement contains a general guide, rules and menu for group reservations and private events. <a href='https://andalousgrill.com/data/Andalous-Mediterranean-Grill-Group-Reservation-and-Event-Packet.pdf'>You may download the packet to review our policies</a>. A completed form is required to finalize your reservation.</p>
              </div>
              <div className='column is-two-fifths'>
                <ReservationForm location={this.props.match.params.location} />
              </div>
            </div>
          </div>
        </MainLayout>
      </>
    )
  }
}

export default withRouter(EventsBanquets)
