import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const galleryLayout = props => (
  <div className='hero is-fullheight'>
    <div className='hero-head'>
      <Link to={props.returnUrl} className='is-size-4 has-text-grey-dark is-block is-pulled-right has-gallery-close-padding'>
        <FontAwesomeIcon icon='times' />
      </Link>
    </div>
    <div className='hero-body has-modified-hero-padding gallery-type'>
      {props.children}
    </div>
  </div>
)

export default galleryLayout
