import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage } from 'formik'
import { MultiSelect } from 'react-sm-select'
import Recaptcha from 'react-recaptcha'

import contactFormWrapper from '../../../hoc/contactFormWrapper'
import locationsData from '../../../data/locations.json'
import FormStatus from '../../FormStatus/FormStatus'

class ContactForm extends Component {
  constructor (props) {
    super(props)
    this.recaptchaInstance = ''
  }

  componentDidMount () {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  }

  componentDidUpdate () {
    if (!this.props.status.initial) this.recaptchaInstance.reset()
  }

  render () {
    const {
      values,
      handleSubmit,
      handleBlur,
      handleChange,
      isValid,
      isSubmitting,
      status,
      errors,
      touched,
      setFieldValue,
      setFieldTouched
    } = this.props

    const locations = locationsData.map(location => location.name)
    const locationOptions = locations.map(location => { return { value: location === 'Richardson Buffet' ? 'Richardson' : location, label: location } })

    locationOptions.push({ value: 'Not location specific', label: 'Not location specific' })
    locationOptions.push({ value: 'Online Ordering', label: 'Online Ordering' })

    const recaptchaSiteKey = '6LetSLMUAAAAAF9-JEvkpo-XLi02zjn9B9eNQbaO'

    return (
      <>
        <form onSubmit={handleSubmit}>

          <div className='field is-horizontal'>
            <div className='field-body'>
              <div className='field'>
                <div className='control'>
                  <input
                    type='text'
                    placeholder='First Name'
                    name='first'
                    value={values.first}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={`input${errors.first && touched.first ? ' is-danger' : ''}`}
                  />
                </div>
                <ErrorMessage name='first' render={msg => <p className='help is-danger'>{msg}</p>} />
              </div>
              <div className='field'>
                <div className='control'>
                  <input
                    type='text'
                    placeholder='Last Name'
                    name='last'
                    value={values.last}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={`input${errors.last && touched.last ? ' is-danger' : ''}`}
                  />
                </div>
                <ErrorMessage name='last' render={msg => <p className='help is-danger'>{msg}</p>} />
              </div>
            </div>
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='email'
                placeholder='E-mail address'
                name='email'
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.email && touched.email ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='email' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className={`control${errors.location && touched.location ? ' is-danger' : ''}`}>
              <MultiSelect
                mode='single'
                value={[values.location]}
                onChange={value => {
                  setFieldValue('location', value[0])
                }}
                onBlur={() => setFieldTouched('location', true)}
                options={locationOptions}
                id='location'
                valuePlaceholder='Select a location'
              />
            </div>
            <ErrorMessage name='location' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <textarea
                placeholder='Message'
                name='message'
                value={values.message}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`textarea${errors.message && touched.message ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='message' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <Recaptcha
                sitekey={recaptchaSiteKey}
                render='explicit'
                ref={e => { this.recaptchaInstance = e }}
                verifyCallback={response => {
                  setFieldValue('recaptcha', response)
                }}
                onloadCallback={() => setFieldValue('recaptcha', '')}
              />
            </div>
            <ErrorMessage name='recaptcha' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <button
                type='submit'
                className={`button is-primary${isSubmitting ? ' is-loading' : ''}`}
                disabled={!isValid || isSubmitting}
              >
                <span className='icon'>
                  <FontAwesomeIcon icon='paper-plane' />
                </span>
                <span>Send Message</span>
              </button>
            </div>
          </div>

          <FormStatus status={status} />

        </form>
      </>
    )
  }
}

export default contactFormWrapper(ContactForm)
