import * as yup from 'yup'

import careerData from '../data/career.json'

const xp = careerData.xp
const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
const today = new Date()

const getTomorrow = () => {
  var tzOff = today.getTimezoneOffset() * 60 * 1000
  var todayTime = today.getTime()
  var target = new Date()
  var tzOff2

  todayTime += (1000 * 60 * 60 * 24)
  target.setTime(todayTime)

  tzOff2 = target.getTimezoneOffset() * 60 * 1000
  if (tzOff !== tzOff2) {
    var diff = tzOff2 - tzOff
    todayTime += diff
    target.setTime(todayTime)
  }

  return new Date(target.setHours(0, 0, 0, 0))
}

const schema = yup.object().shape({
  name: yup.string('Letters only').required('Required').min(5, 'Min. 5 chars').max(100, 'Max. 100 chars'),
  email: yup.string('Email Address').required('Required').email('Enter a valid email address'),
  phone: yup.string().required('Required').matches(phoneRegExp, 'Enter a valid phone number').min(10, 'Enter a valid phone number').max(20, 'Enter a valid phone number'),
  positions: yup.array().min(1, 'You have to choose at least 1').required('Required'),
  locations: yup.array().min(1, 'You have to choose at least 1').required('Required'),

  xp: yup.mixed().oneOf(xp, 'Please choose one of the given values').required('Required'),
  previous: yup.string('Letters only').max(250, 'Max. 250 chars'),
  days: yup.array().of(yup.string().min(6, 'Choose a valid day(s) of the week').max(9, 'Choose a valid day(s) of the week')).required('Required').min(2, 'Choose at least 2 days').max(7, 'You have only 7 days to choose from'),
  hours: yup.number().typeError('Number only').integer('Please choose a number from 20 to 60').positive('Please choose a number from 20 to 60').min(20, 'You have to take at least 20 hours per week').max(60, "You can't take more than 60 hours per week").required('Required'),
  date: yup.date().typeError('Please enter a valid date').required('Please enter a valid date').min(getTomorrow(), 'The min. date is tomorrow'),
  recaptcha: yup.string().required('Required')
})

export default schema
