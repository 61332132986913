// External packages
import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faTimes, faAngleLeft, faAngleRight, faPaperPlane, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

// Internal packages
import Home from './pages/Home'
import Menu from './pages/Menu'
import EventsBanquets from './pages/EventsBanquets'
import EventsBanquetsGallery from './pages/EventsBanquetsGallery'
import LocationsPage from './pages/LocationsPage'
import About from './pages/About'
import Careers from './pages/Careers'
import Feedback from './pages/Feedback'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsOfService from './pages/TermsOfService'
import Error404 from './pages/Error404'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

library.add(faCheck, faTimes, faAngleLeft, faAngleRight, faPaperPlane, faPlus, faMinus, faFacebook, faTwitter, faInstagram)

class App extends Component {
  render () {
    return (
      <Switch>
        <Route path='/' exact render={props => <Home {...props} />} />
        <Route path='/menu' exact render={props => <Menu {...props} />} />
        <Route path='/events-banquets' exact render={props => <EventsBanquets {...props} />} />
        <Route path='/events-banquets/gallery/:location' exact render={props => <EventsBanquetsGallery {...props} />} />
        <Route path='/events-banquets/gallery' exact render={props => <EventsBanquetsGallery {...props} />} />
        <Route path='/events-banquets/:location' exact render={props => <EventsBanquets {...props} />} />
        <Route path='/locations' exact render={props => <LocationsPage {...props} />} />
        <Route path='/about' exact render={props => <About {...props} />} />
        <Route path='/careers' exact render={props => <Careers {...props} />} />
        <Route path='/feedback' exact render={props => <Feedback {...props} />} />
        <Route path='/privacy-policy' exact render={props => <PrivacyPolicy {...props} />} />
        <Route path='/terms-of-service' exact render={props => <TermsOfService {...props} />} />
        <Route render={props => <Error404 {...props} />} />
      </Switch>
    )
  }
}

export default App
