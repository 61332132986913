import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage } from 'formik'
import Recaptcha from 'react-recaptcha'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import { parseISO } from 'date-fns'
import formatDate from 'date-fns/format'
import { enUS } from 'date-fns/locale'
import { MultiSelect } from 'react-sm-select'

import careerFormWrapper from '../../../hoc/careerFormWrapper'
import FormStatus from '../../FormStatus/FormStatus'
import locationsData from '../../../data/locations.json'
import careerData from '../../../data/career.json'

class CareerForm extends Component {
  constructor (props) {
    super(props)
    this.recaptchaInstance = ''
  }

  componentDidMount () {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    const datePickerInput = document.querySelector('.DayPickerInput input')
    datePickerInput.classList.add('input')
  }

  componentDidUpdate () {
    if (!this.props.status.initial) this.recaptchaInstance.reset()
  }

  parseInputDate = (str) => {
    const parsed = parseISO(str)
    if (DateUtils.isDate(parsed)) return parsed
    return undefined
  }

  formatInputDate = (date, format) => {
    return formatDate(date, format, { locale: enUS })
  }

  handleHoursChange = val => {
    let inputValue = val
    if (isNaN(inputValue)) return
    if (/^0/.test(inputValue)) inputValue = inputValue.replace(/^0/, '')
    if (!this.props.touched.hours) this.props.setFieldTouched('hours')
    if (inputValue !== '') inputValue = Number(inputValue)
    this.props.setFieldValue('hours', inputValue)
  }

  handleAddHour = () => {
    const hoursValue = this.props.values.hours
    if (hoursValue === 60 || isNaN(hoursValue)) return
    if (hoursValue === '') {
      this.props.setFieldValue('hours', 20)
      if (!this.props.touched.hours) this.props.setFieldTouched('hours')
    } else {
      this.props.setFieldValue('hours', (hoursValue + 1))
    }
  }

  handleSubtractHour = () => {
    const hoursValue = this.props.values.hours
    if (hoursValue === 20 || hoursValue === '' || isNaN(hoursValue)) return
    this.props.setFieldValue('hours', (hoursValue - 1))
  }

  formatPhoneValue = (val) => {
    if (val.length < 10) return val
    const eliminationRegExp = /[\s()-]/gi
    const cleansedVal = val.replace(eliminationRegExp, '')
    const formattedVal = ['(', cleansedVal.slice(0, 3), ')', ' ', cleansedVal.slice(3, 6), '-', cleansedVal.slice(6)].join('')
    return formattedVal
  }

  render () {
    const locations = locationsData.map(location => location.name)

    const locationOptions = locations.map(location => { return { value: location, label: location } })
    const xpOptions = careerData.xp.map(xp => { return { value: xp, label: xp } })
    const positionOptions = careerData.positions.map(pos => { return { value: pos, label: pos } })
    const dayOptions = careerData.days.map(day => { return { value: day, label: day } })

    const {
      values,
      handleSubmit,
      handleBlur,
      handleChange,
      isValid,
      isSubmitting,
      status,
      errors,
      touched,
      setFieldValue,
      setFieldTouched
    } = this.props

    const recaptchaSiteKey = '6LetSLMUAAAAAF9-JEvkpo-XLi02zjn9B9eNQbaO'

    return (
      <>
        <form onSubmit={handleSubmit}>

          <div className='field'>
            <div className='control'>
              <input
                type='text'
                placeholder='First & Last Name'
                name='name'
                id='name'
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.name && touched.name ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='name' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='email'
                placeholder='E-mail address'
                name='email'
                id='email'
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.email && touched.email ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='email' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='tel'
                placeholder='Telephone number'
                name='phone'
                id='phone'
                value={values.phone}
                onBlur={handleBlur}
                onChange={e => {
                  const newValue = this.formatPhoneValue(e.target.value)
                  setFieldValue('phone', newValue)
                }}
                className={`input${errors.phone && touched.phone ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='phone' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className={`control${errors.positions && touched.positions ? ' is-danger' : ''}`}>
              <MultiSelect
                mode='list'
                value={values.positions}
                onChange={value => {
                  setFieldValue('positions', value)
                }}
                onBlur={() => setFieldTouched('positions', true)}
                options={positionOptions}
                id='positions'
                allSelectedLabel='All positions are selected'
                valuePlaceholder='Position(s) for which you are applying'
                className='input'
                hasSelectAll
                resetable
              />
            </div>
            <ErrorMessage name='positions' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className={`control${errors.locations && touched.locations ? ' is-danger' : ''}`}>
              <MultiSelect
                mode='list'
                value={values.locations}
                onChange={value => {
                  setFieldValue('locations', value)
                }}
                onBlur={() => setFieldTouched('locations', true)}
                options={locationOptions}
                id='locations'
                allSelectedLabel='All locations are selected'
                valuePlaceholder='Locations at which you could be available'
                hasSelectAll
                resetable
              />
            </div>
            <ErrorMessage name='locations' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className={`control${errors.xp && touched.xp ? ' is-danger' : ''}`}>
              <MultiSelect
                mode='single'
                value={[values.xp]}
                onChange={value => {
                  setFieldValue('xp', value[0])
                }}
                onBlur={() => setFieldTouched('xp', true)}
                options={xpOptions}
                id='xp'
                valuePlaceholder='Experience in the restaurant industry'
              />
            </div>
            <ErrorMessage name='xp' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='text'
                placeholder='Restaurants you worked at before'
                name='previous'
                id='previous'
                value={values.previous}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.previous && touched.previous ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='previous' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className={`control${errors.days && touched.days ? ' is-danger' : ''}`}>
              <MultiSelect
                mode='list'
                value={values.days}
                onChange={value => {
                  setFieldValue('days', value)
                }}
                onBlur={() => setFieldTouched('days', true)}
                options={dayOptions}
                id='days'
                allSelectedLabel='All days are selected'
                valuePlaceholder='Check week days available'
                hasSelectAll
                resetable
              />
            </div>
            <ErrorMessage name='days' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field has-addons'>
            <div className='control'>
              <button type='button' className='button' onClick={this.handleSubtractHour}>
                <span className='icon'>
                  <FontAwesomeIcon icon='minus' />
                </span>
              </button>
            </div>
            <div className='control is-expanded'>
              <input
                type='text'
                placeholder='Maximum Hours Available per Week'
                name='hours'
                id='hours'
                value={values.hours}
                onBlur={handleBlur}
                onChange={ev => this.handleHoursChange(ev.target.value)}
                className={`input${errors.hours && touched.hours ? ' is-danger' : ''}`}
              />
            </div>
            <div className='control'>
              <button type='button' className='button' onClick={this.handleAddHour}>
                <span className='icon'>
                  <FontAwesomeIcon icon='plus' />
                </span>
              </button>
            </div>
          </div>
          <ErrorMessage name='hours' render={msg => <p className='help is-danger translated-slightly-upwards has-tiny-bottom-margin'>{msg}</p>} />

          <div className='field'>
            {/* <label htmlFor='date' className='label'>Date Available to Start</label> */}
            <div className={`control translated-day-picker${errors.date && touched.date ? ' date-danger' : ''}`}>
              <DayPickerInput
                placeholder='Date Available to Start'
                onDayChange={day => setFieldValue('date', day)}
                dayPickerProps={{
                  fromMonth: new Date(),
                  disabledDays: {
                    before: new Date()
                  },
                  selectedDays: values.date
                }}
                inputProps={{
                  name: 'date',
                  onBlur: handleBlur,
                  type: 'text',
                  id: 'date',
                  readOnly: true
                }}
                value={values.date}
                formatDate={this.formatInputDate}
                format='MM/dd/yy'
                parseDate={this.parseInputDate}
                keepFocus={false}
              />
            </div>
            <ErrorMessage name='date' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <Recaptcha
                sitekey={recaptchaSiteKey}
                render='explicit'
                ref={e => { this.recaptchaInstance = e }}
                verifyCallback={response => {
                  setFieldValue('recaptcha', response)
                }}
                onloadCallback={() => setFieldValue('recaptcha', '')}
              />
            </div>
            <ErrorMessage name='recaptcha' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <button
                type='submit'
                className={`button is-primary${isSubmitting ? ' is-loading' : ''}`}
                disabled={!isValid || isSubmitting}
              >
                <span className='icon'>
                  <FontAwesomeIcon icon='paper-plane' />
                </span>
                <span>Submit</span>
              </button>
            </div>
          </div>

          <FormStatus status={status} />

        </form>
      </>
    )
  }
}

export default careerFormWrapper(CareerForm)
