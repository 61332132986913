import React from 'react'

const announcement = props => {
  let content = ''
  let announcementSize = ''
  let messageSize = ''
  if (props.smallscreen) {
    announcementSize = 'is-medium'
    messageSize = 'is-size-6'
  } else {
    announcementSize = 'is-large'
    messageSize = 'is-size-4'
  }
  if (props.announcement.active) {
    content = (
      <div className={`message ${announcementSize} is-announcement is-warning`}>
        <div className='message-header'>
          <p>{props.announcement.title}</p>
        </div>
        <div className={`message-body ${messageSize} has-text-centered has-text-black`}>{props.announcement.message}</div>
      </div>
    )
  }
  return (
    <>
      {content}
    </>
  )
}

export default announcement
