import React from 'react'

const thirdCardWithImageTitleOnTop = props => (
  <div className='column is-4 is-flex has-columndirection'>
    <p className='is-size-4 has-text-weight-semibold'>{props.title}</p>
    <figure className='image is-square third-card-with-image-title-on-top__image'>
      <img src={props.imageSource} alt={props.imageAlt} />
    </figure>
    <div className='box is-radiusless is-shadowless is-flex has-columndirection is-flexible'>
      {props.children}
    </div>
  </div>
)

export default thirdCardWithImageTitleOnTop
