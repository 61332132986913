import React from 'react'

// internal modules
import RegularCardContainer from '../UiElements/RegularCardContainer'
import Location from './Location'

const componentName = props => {
  const content = props.locationData.map((locationElement, index) => {
    return <Location locationItem={locationElement} key={index} />
  })
  return (
    <RegularCardContainer addClasses={['default-responsive-cards']}>
      {content}
    </RegularCardContainer>
  )
}

export default componentName
