import React, { Component } from 'react'
import axios from 'axios'

import { baseURL } from '../config'

const dataSources = {
  featuredFoodCategories: {
    api: new URL('items', baseURL).href,
    static: '/data/featuredFoodCategories.json'
  },
  announcement: {
    api: new URL('config/motd', baseURL).href,
    static: '/data/announcement.json'
  }
}

class FetchData extends Component {
  state = {
    isLoading: false,
    data: null,
    error: null
  }

  componentDidMount () {
    this.setState({ isLoading: true })
    axios.get(dataSources[this.props.requestedData][this.props.static ? 'static' : 'api'])
      .then(result => {
        this.setState({
          data: result.data[this.props.dataField || this.props.requestedData],
          isLoading: false
        })
      })
      .catch(error => this.setState({
        error,
        isLoading: false
      }))
  }

  render () {
    const ComponentToRender = this.props.for
    let contentToRender = ''
    if (this.state.isLoading) {
      contentToRender = (
        <p>Data is loading</p>
      )
    } else if (this.state.error) {
      contentToRender = (
        <p>{this.state.error.message}</p>
      )
    } else if (!this.state.data) {
      contentToRender = (
        <p>Data could not be loaded</p>
      )
    } else {
      if (this.props.addProps) {
        contentToRender = <ComponentToRender {...{ [this.props.dataProp]: this.state.data, ...this.props.addProps }} />
      } else {
        contentToRender = <ComponentToRender {...{ [this.props.dataProp]: this.state.data }} />
      }
    }
    return (
      <>
        {contentToRender}
      </>
    )
  }
}

export default FetchData
