import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// internal modules
import DesktopNavBar from '../components/DesktopNavBar/DesktopNavBar'
import MainLayout from '../containers/MainLayout'
import Announcement from '../components/Announcement/Announcement'
import FetchData from '../helpers/fetchData'
import Footer from '../components/Footer/Footer'

class Home extends Component {
  state = {
    smallScreen: false
  }

  componentDidMount () {
    window.addEventListener('load', this.smallScreenDetector)
    window.addEventListener('resize', this.smallScreenDetector)
    this.smallScreenDetector()
  }

  componentWillUnmount () {
    window.removeEventListener('load', this.smallScreenDetector)
    window.removeEventListener('resize', this.smallScreenDetector)
  }

  smallScreenDetector = () => {
    const smallScreen = window.innerWidth <= 1024
    if (smallScreen !== this.state.smallScreen) this.setState({ smallScreen })
  }

  render () {
    const temporaryBackground = 'url("https://beta.andalousgrill.com/image-uploads-manual/website-main-hero.jpg")'
    const positionAboveBody = (
      <FetchData for={Announcement} dataProp='announcement' requestedData='announcement' dataField='value' addProps={{ smallscreen: this.state.smallScreen }} />
    )
    return (
      <>
        <Helmet>
          <title>Andalous Mediterranean Grill - Home</title>
        </Helmet>
        <DesktopNavBar />
        <MainLayout
          fullheight='true'
          style={{ backgroundImage: temporaryBackground }}
          className='hero-body home-page-body'
          passedabovebody={positionAboveBody}
          noFooter
        >
          <div className='container has-text-centered home-page__container'>
            <img src='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/andalous-logo-500w.png' alt='Andalous logo' className='hero-image' />
            <div className='buttons are-medium is-centered home-buttons'>
              <Link to='/menu' className={`button${this.state.smallScreen ? ' is-size-6 is-dark' : ' is-dark'}`}>Menu</Link>
              <a className={`button${this.state.smallScreen ? ' is-size-6 is-dark' : ' is-dark'}`} href='https://order.andalousgrill.com'>Order Online</a>
            </div>
          </div>
          <Footer notHero classes='home-page__footer' />
        </MainLayout>
      </>
    )
  }
}

export default Home
