import React from 'react'

const regularUnderlinedTitleWithDesc = props => (
  <>
    <h2 className='is-size-2 is-size-3-mobile has-text-weight-bold has-bottom-border-gray has-light-bottom-margin'>
      {props.title}
    </h2>
    <p className='has-medium-bottom-margin'>
      {props.description}
    </p>
  </>
)

export default regularUnderlinedTitleWithDesc
