import React from 'react'

import ThirdCardWithImageTitleOnTop from '../UiElements/ThirdCardWithImageTitleOnTop'
import ButtonLinkPairEqualWidth from '../UiElements/ButtonLinkPairEqualWidth'

const location = props => (
  <ThirdCardWithImageTitleOnTop
    title={props.locationItem.name}
    imageSource={props.locationItem.image}
    imageAlt={props.locationItem.title}
  >
    <p className='has-line-breaks has-light-bottom-margin is-flexible'>
      {props.locationItem.address}<br />
      {props.locationItem.phone}
    </p>
    <p className='has-line-breaks has-medium-bottom-margin is-flexible'>
      Hours of Operation:<br />
      {props.locationItem.availability}
    </p>
    {props.locationItem.additional && <p className='has-line-breaks has-medium-bottom-margin is-flexible'>Buffet Pricing:<br />{props.locationItem.additional}</p>}
    <ButtonLinkPairEqualWidth
      link1={props.locationItem.link}
      buttonText1='Order Online'
      link2={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(props.locationItem.address)}`}
      buttonText2='Google Maps'
    />
  </ThirdCardWithImageTitleOnTop>
)

export default location
