import { withFormik } from 'formik'
import axios from 'axios'
import unwrap from 'async-unwrap'

import ReservationSchema from '../validation/ReservationSchema'
import { baseURL } from '../config'

const reservationFormWrapper = withFormik({
  validationSchema: ReservationSchema,
  handleSubmit: async (formData, { setStatus, resetForm, setSubmitting }) => {
    const sendToApi = { ...formData }
    const [err, res] = await axios.post(new URL('events', baseURL).href, sendToApi)[unwrap] // eslint-disable-line no-unused-vars

    const statusToSet = { initial: false }

    if (err) {
      statusToSet.error = 'Something went wrong. Please try again later.'
      setStatus(statusToSet)
      setSubmitting(false)
      setTimeout(() => {
        setStatus({ ...statusToSet, initial: true })
      }, 5000)
      return
    }

    resetForm()
    statusToSet.success = 'Reservation request submitted successfully.'
    setStatus(statusToSet)
    setSubmitting(false)
    setTimeout(() => {
      setStatus({ ...statusToSet, initial: true })
    }, 5000)
  },
  mapPropsToValues: () => ({
    name: '',
    email: '',
    phone: '',
    location: '',
    guests: 5,
    date: '',
    preferred: '',
    message: '',
    recaptcha: ''
  }),
  mapPropsToErrors: () => ({
    readyForSubmission: 'Not ready'
  }),
  mapPropsToStatus: () => ({
    initial: true
  }),
  displayName: 'ReservationForm'
})

export default reservationFormWrapper
