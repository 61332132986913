import React from 'react'

const fourthCardWithoutImage = props => (
  <div className='column is-half is-flex'>
    <div className='box it-is-fullwidth is-radiusless is-shadowless'>
      <p className='has-line-breaks is-size-5 has-text-centered has-medium-bottom-margin has-text-weight-semibold'>
        {props.title}
      </p>
      {props.children}
    </div>
  </div>
)

export default fourthCardWithoutImage
