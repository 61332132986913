import React from 'react'
import { Helmet } from 'react-helmet'

import DesktopNavBar from '../components/DesktopNavBar/DesktopNavBar'
import MainLayout from '../containers/MainLayout'
import ContactForm from '../components/Forms/ContactForm/ContactForm'

const feedback = props => (
  <>
    <Helmet>
      <title>Questions, Comments, Feedback - Andalous Mediterranean Grill</title>
    </Helmet>
    <DesktopNavBar />
    <MainLayout fullheight='true' alignstart='true'>
      <div className='container'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <h1 className='title is-size-2 is-size-3-mobile'>Feedback</h1>
            <p className='has-medium-bottom-margin'>We value our customers and strive to deliver a high quality dining experience. With a large variety of restaurants to choose from, we appreciate your choice to dine with us.</p>
            <p className='has-medium-bottom-margin'>From questions about our online ordering, catering service, to special events to customer-care and feedback we invite any questions, comments or inquiries.</p>
            <p className='has-medium-bottom-margin'>
              We look forward to your next visit.
            </p>
          </div>
          <div className='column is-half'>
            <ContactForm />
          </div>
        </div>
      </div>
    </MainLayout>
  </>
)

export default feedback
