import * as yup from 'yup'

import locationsData from '../data/locations.json'
import packagesData from '../data/packages.json'

const locations = locationsData.map(location => location.name === 'Richardson Buffet' ? 'Richardson' : location.name)
const packages = packagesData.map(packageItem => packageItem.title.replace(/\n\n/g, '').replace(/\n/g, ' '))
const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

const schema = yup.object().shape({
  name: yup.string('Letters only').required('Required').min(4, 'Min. 4 chars').max(100, 'Max. 100 chars'),
  email: yup.string('Email Address').required('Required').email('Enter a valid email address'),
  phone: yup.string().required('Required').matches(phoneRegExp, 'Enter a valid phone number').min(10, 'Enter a valid phone number').max(20, 'Enter a valid phone number'),
  location: yup.mixed().oneOf(locations, 'Please choose one of our locations'),

  guests: yup.number().typeError('Number only').integer('Please enter a positive natural number').positive('Please enter a positive natural number').required('Required').min(5, 'Minimum 5 guests are required').max(300, 'Maximum 100 guests are allowed'),

  date: yup.date().typeError('Please enter a valid date').required('Please enter a valid date'),
  preferred: yup.mixed().oneOf(packages, 'Please select one of our packages'),
  message: yup.string('Letters only').min(10, 'Min. 10 chars').max(1000, 'Max. 1000 chars'),
  recaptcha: yup.string().required('Required')
})

export default schema
